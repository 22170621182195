.App {
  text-align: center;

}
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

/* Importing Rubik Glitch font using Google Fonts API */
@import url('https://fonts.googleapis.com/css2?family=Rubik+Glitch&display=swap');

/* Add more @import rules for other fonts as needed */
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');
/* Applying Anton font to heading */
.anton-font {
  font-family: 'Anton', sans-serif;
}

/* Applying Rubik Glitch font to paragraphs */
.rubik-glitch-font {
  font-family: 'Rubik Glitch', sans-serif;
}

.unbounded-font {
  font-family: 'Unbounded', sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text{
  color: #E2D705;
}
.text-background-colour{
  background-color: #E2D705;
}
.menu a:hover{
  color: #E2D705;
}
button:hover{
  border-color: #E2D705;
}

.pb-7 {
  padding-bottom: 10.75rem;
}

.background-colour-for-title {
  background: #E2D705
}

.responsive-button {
  color: white;
  font-weight: bolder;
  border-radius: 9999px; /* Use a high value for a rounded shape */
  font-size: 1.25rem; /* Adjust according to your design */
  padding: 0.625rem 1.25rem; /* Adjust according to your design */
  text-align: center;
  margin-right: 0.25rem; /* Adjust according to your design */
  margin-bottom: 0.5rem; /* Adjust according to your design */
  border: 2px solid #FFD700; /* Adjust color and thickness according to your design */
}
.psh{
  overflow: hidden;
  width: 100%;
  height: 100%;
  /*padding-top: 30px;*/
  /* padding-bottom: 30px; */

}
.slick-slide img {
  /*padding-top: 10px;*/
  padding-left: 10px;
}
.ide h5{
  padding-left: 85px;
}
.ide p{
  text-align: center;
  color: #fec016;
}
.ide span{
  align-items: center;
  text-align: center;
  color: white;
  margin-left: 25px;
}
span:hover{
  /*color: black;*/
  /*font-weight: ;*/
}
.slick-center {
  transform: scale(2.1);
}
.slicks{
  padding-top: 35px;
  padding-bottom: 15px;
  /*background: linear-gradient(90deg, #fbbf24 7%, #fcd34d 21%, #d97706 75%);*/
  background: #020617
}

.slick-prev:before, .slick-next:before {
  color: #000 !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu{
  z-index: 999;
}
.drop {
  display: flex;
  justify-content: flex-end;
}
.text:hover{
  color: #E2D705;
}

.border:hover {
  border-color: #E2D705; /* Change border color on hover */
}
li{
  border: black;
}
li:hover{
  border-color: #E2D705;
}
.text-6xl{
  font-size: xx-large;
}
